import React from "react";
import "./styles.css";
import IconAvion from "../../assets/icono_avion.svg";
import ImagenFlecha from "../../assets/flecha1 (2).png";
function Enviado(props) {
  return (
    <div className="container-enviado">
      <div className="contenedor-box">
        <div className="box-enviado">
          <p>
            Su solicitud ha sido enviada
            <br />
            exitosamente
          </p>
          <img src={IconAvion} alt="Icono Avion" />
          <p className="parrafo-info">
            Muchas gracias por culminar la Visita Digital Autoguiada y esperamos
            que sea de utilidad para usted y sus pacientes. Recuerde la
            importancia de prescribir elementos de cuidado oral de manera
            formal; aquí mismo le ofrecemos la posibilidad de hacerlo
            digitalmente.
          </p>
        </div>
        <div className="container-botones">
          <button
            className={"btn-logout text-center"}
            onClick={() => {
              localStorage.removeItem("token");
              window.location.href = "/";
            }}
          >
            Cerrar Sesión
          </button>
          <div className="contenedor-buton-p">
            <img src={ImagenFlecha} alt="" className="ImagenFlecha" />
            <p>
              Para ir a la prescripción debe regresar al HOME, y allí encontrará
              el ícono de la PRESCRIPCIÓN DIGITAL
            </p>
            <button
              className={"btn-home text-center"}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Enviado;
