import React, {useEffect, useState} from 'react';
import './styles.css'
import {registrosServices} from "../../Services/registrosServices";
import PopUpGenerico from "../PopUpGenerico/PopUpGenerico";
import AprobarRegistro from "../AprobarRegistro/AprobarRegistro";

function RegistrosPendientes(props) {
    const [data, setData] = useState([])
    const [numPages, setNumPages] = useState([]);
    const [arrayOriginal, setArrayOriginal] = useState([]);
    const [showPopUpAprobado, setShowPopUpAprobado] = useState(false);
    const [datos, setDatos] = useState({});
    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])
    const getData = async () => {
        const res = await registrosServices.getRegistrosPendientes();
        if (res.status === 200) {
            const numPage = res.data.length / 5
            let arrayNums = []
            for (let i = 0; i <= numPage; i++) {
                arrayNums.push(i + 1);
            }
            setNumPages(arrayNums)
            setArrayOriginal(res.data)
            const array = await paginate(res.data, 1, 5);
            setData(array);
        }
    }
    const searchInArray = async (e) => {
        let texto = (e.target.value).toLowerCase();
        const newData = data.filter((registro) => {
            const nombres = registro.nombres.toLowerCase();
            const apellidos = registro.apellidos.toLowerCase();
            const cedula = registro.cedula.toLowerCase();
            const email = registro.email.toLowerCase();
            const campo = nombres + " " + apellidos + " " + cedula + " " + email;
            return campo.indexOf(texto) > -1;
        })
        setData(newData);
        if (!e.target.value) {
            await getData();
        }
    }
    const paginate = async (array, page_number, page_size) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size)
    }
    const movePage = async (numpage) => {
        const newArray = await paginate(arrayOriginal, numpage, 5);
        setData(newArray)
    }
    const cerrarPopup = () => {
        setShowPopUpAprobado(false);
    }
    return (
        <div className={"container-registros"}>
            <h1>Registros pendientes</h1>
            <div className="table">
                <div className="form-group">
                    <h3>Buscar</h3>
                    <input type="text" name="buscar" id="buscar" onChange={async (e) => {
                        await searchInArray(e)
                    }}/>
                </div>
                {data ?
                    <ul className={"list-pagination"}>
                        {numPages.map(num => (
                            <li key={num} onClick={async () => {
                                await movePage(num);
                            }}>{num}</li>
                        ))}
                    </ul> : null}
                <table className={"table-generica"} cellPadding={0} cellSpacing={0}>
                    <thead>
                    <tr className={"cabecera"}>
                        <th>Nombre</th>
                        <th>Cedula</th>
                        <th className={"th-big"}>Email</th>
                        <th>Opciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data ? data.map((registro) => (
                        <tr className={"fila-registro"} key={registro.cedula} onClick={() => {
                            setDatos(registro)
                            setShowPopUpAprobado(true)
                        }}>
                            <td>{registro.nombres + ' ' + registro.apellidos}</td>
                            <td>{registro.cedula}</td>
                            <td>{registro.email}</td>
                            <td>
                                <div className="contenedor-opcion">
                                    <p className={"opcion-tabla"} onClick={() => {
                                        setDatos(registro)
                                        setShowPopUpAprobado(true)
                                    }}>
                                        Aprobar registro
                                    </p>
                                </div>
                            </td>
                        </tr>
                    )) : null}
                    </tbody>
                </table>
            </div>
            {showPopUpAprobado ?
                <PopUpGenerico cerrarPopup={cerrarPopup}
                               componente={<AprobarRegistro datos={datos} getData={getData}/>}/> : null}
        </div>
    );
}

export default RegistrosPendientes;