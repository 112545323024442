import Axios from 'axios';

const accionesServices = {}
accionesServices.postAccion = async (datos) => {
    try {
        return await Axios.post('https://visita.kagencia.com/api/acciones', datos).catch((err) => {
            return err.response;
        })
    } catch (e) {
        return e;
    }
}
export {accionesServices}