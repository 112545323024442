import Axios from 'axios';
const registrosServices = {}
const token = localStorage.getItem('token')
registrosServices.getRegistrosPendientes = async () => {
    try {
        return await Axios.get('https://visita.kagencia.com/api/registro', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).catch((err) => {
            return err.response
        })
    } catch (e) {
        return e;
    }
}
registrosServices.aprobarRegistro = async (datos) => {
    try {
        return await Axios.post('https://visita.kagencia.com/api/registro/aprobar', datos, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).catch((err) => {
            return err.response;
        })
    } catch (e) {
        return e;
    }
}
registrosServices.notificarDoctor = async (datos) => {
    try {
        return await Axios.post('https://visita.kagencia.com/api/registro/sendmail', datos, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).catch((err) => {
            return err.response;
        })
    } catch (e) {
        return e;
    }
}
registrosServices.postDoctor = async (datos) => {
    try {
        return await Axios.post('https://visita.kagencia.com/api/registro', datos, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).catch((err) => {
            return err.response
        })
    } catch (e) {
        return e;
    }
}
export {registrosServices}