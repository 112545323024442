import React, {useState} from 'react';
import './styles.css';
import CrearCapitulo from "../CrearCapitulo/CrearCapitulo";
import {visitasServices} from "../../Services/visitasServices";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer, toast} from 'react-toastify';
import * as Swal from "sweetalert2";

function CrearVisita(props) {
    const [datos, setDatos] = useState({
        descripcion: ''
    })
    const [descripcionVisita, setDescripcionVisita] = useState('')
    const [idVisita, setIdVisita] = useState('');
    const [showCreateCap, setShowCreateCap] = useState(false);
    const onSubmitForm = async (e) => {
        e.preventDefault();
        const res = await visitasServices.postVisita(datos);
        if (res.status === 200) {
            toast.success(res.data.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            setIdVisita(res.data.visita);
            setDescripcionVisita(res.data.descripcion);
            e.target.reset();
        } else {
            toast.error(res.data.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            e.target.reset();
            Swal.fire({
                title: '¿Deseas eliminar la visita existente?',
                text: "No podras revertir esto.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#dd3333',
                confirmButtonText: 'Sí.',
                cancelButtonText: 'No.'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const res = await visitasServices.deleteVisita();
                    if (res.status === 200) {
                        await Swal.fire(
                            'Eliminada',
                            'Se ha eliminado la visita anterior',
                            'success'
                        )
                    } else {
                        toast.error(res.data.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
                    }
                }
            })
        }
    }
    const createCap = () => {
        setShowCreateCap(true);
    }
    return (
        <div className={"container-crear-visita"}>
            <h1>Crear Visita Autoguiada</h1>
            {
                idVisita ? <p className={"inputs-forms"} style={{textAlign: 'center'}}>{descripcionVisita}</p> :
                    <form id={"form-crearvisita"} onSubmit={onSubmitForm}>
                        <div className="form-group">
                            <p>Descripción</p>
                            <input type="text" name={"descripcion"} className={"inputs-forms"} onChange={(e) => {
                                setDatos({...datos, descripcion: e.target.value})
                            }}/>
                        </div>
                        <input type="submit" className={"btn-enviar"} value={"Crear Visita"}/>
                    </form>
            }
            {idVisita ?
                <div className="contenedor-btn-capitulo">
                    <button onClick={createCap} className={"btn-generico"}>Añadir capitulo</button>
                </div> : null
            }
            {showCreateCap ? <CrearCapitulo id={idVisita}/> : null}
            <ToastContainer/>
        </div>
    );
}

export default CrearVisita;