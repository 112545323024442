import React from 'react';
import './styles.css'
import BtnCerrar from "../../assets/cerrar_popup.svg";
function PopUpGenerico(props) {

    return (
        <div className={"container-popup"}>
            <img src={BtnCerrar} onClick={()=>props.cerrarPopup()} className={"btn-cerrar"} alt=""/>
            <div className="contenedor-componente">
                {props.componente}
            </div>
        </div>
    );
}

export default PopUpGenerico;