import React from 'react';
import './styles.css'
import {NavLink} from "react-router-dom";

function MensajeComplete(props) {
    return (
        <div className={"container-complete"}>
            <div className="box-complete">
                <h1>Esta visita ya fue realizada, si quiere volverla a ver, ingrese al material de estudio que encuentra en
                    el home.</h1>
                <NavLink to={"/"} className="btn-generico" style={{textAlign:'center'}}>Regresar</NavLink>
            </div>

        </div>
    );
}

export default MensajeComplete;