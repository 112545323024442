import React, {useEffect, useState} from 'react';
import './styles.css'
import {visitasServices} from "../../Services/visitasServices";
import PopUpVideox from "../PopUpVideo/PopUpVideo";
import PopUpGenerico from "../PopUpGenerico/PopUpGenerico";
import CrearCapitulo from "../CrearCapitulo/CrearCapitulo";
import {capituloService} from "../../Services/capituloServices";
import {toast, ToastContainer} from "react-toastify";

function EditarCapitulo(props) {
    const [capitulos, setCapitulos] = useState([])
    const [video, setVideo] = useState('');
    const [showEditCap, setShowEditCap] = useState(false);
    const [showPopUpVideo, setShowPopUpVideo] = useState(false);
    const [datos, setDatos] = useState({});
    useEffect(() => {
        getData();
    }, [])
    const cerrarPopup = () => {
        setVideo('');
        setShowPopUpVideo(false);
        setShowEditCap(false)
    }
    const getData = async () => {
        const res = await visitasServices.getVisita();
        if (res.status === 200) {
            setCapitulos(res.data[0].capitulos)
        }
    }
    const deleteCap = async (idCapitulo) => {
        const res = await capituloService.deleteCapitulo(idCapitulo);
        if (res.status === 200) {
            toast.success(res.data.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            await getData();
        } else {
            toast.error(res.data.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        }
    }
    return (
        <div className={"container-editarcap"}>
            <h2>{props.delete ? "Eliminar Capitulo" : "Editar Capitulo"}</h2>
            <div className={"contenedor-capitulos"}>
                {
                    capitulos.map((capitulo) => (
                        <div key={capitulo.idCapitulo} className={"contenedor-capitulos__capitulo"}>
                            <h3>Codigo Capitulo</h3>
                            <p>{capitulo.idCapitulo}</p>
                            <h3>Video</h3>
                            <div className="container-capitulo-video">
                                <p>{capitulo.video}</p>
                                <button className={"btn-generico"} onClick={() => {
                                    setVideo(capitulo.video);
                                    setShowPopUpVideo(true);
                                }}>Ver video
                                </button>
                            </div>
                            <h3>Descripción</h3>
                            <p className={"contenedor-capitulo__descripcion"}>{capitulo.descripcion}</p>
                            {props.delete ? <button className="btn-generico" onClick={async () => {
                                await deleteCap(capitulo.idCapitulo)
                            }
                            }>Eliminar Capitulo
                            </button> : <button className="btn-generico" onClick={() => {
                                setShowEditCap(true)
                                setDatos(capitulo);
                            }}>Editar Capitulo
                            </button>}

                        </div>
                    ))
                }
            </div>
            {showEditCap ?
                <PopUpGenerico cerrarPopup={cerrarPopup}
                               componente={<CrearCapitulo editar={true} getData={getData} datos={datos}/>}/> : null}
            {showPopUpVideo ? <PopUpVideox video={`https://visita.kagencia.com/api/videos/${video}`} cerrarPopup={cerrarPopup}/>
                : null}
            <ToastContainer/>
        </div>
    );
}

export default EditarCapitulo;