import React, {useState} from 'react';
import './styles.css'
import IconCedula from '../../assets/ico_cedula.svg';
import IconPassword from '../../assets/contrasena.svg'
import ModalErrorSolicitar from "../ModalForm/ModalErrorSolicitar";
import {sessionServices} from "../../Services/sessionServices";

function AdminLogin(props) {
    const [error, setError] = useState(false);
    const [datos, setDatos] = useState({
        code: '',
        password: ''
    })
    const validarForm = () => {
        const form = document.getElementById('form-login');
        if (form.elements['code'].value === '' || form.elements['password'].value === '') {
            setError(true)
        }
    }
    const onSubmitForm = async (e) => {
        e.preventDefault();
        validarForm();
        const res = await sessionServices.loginAdmin(datos);
        if (res.status === 200) {
            localStorage.setItem('token', res.data.token)
            window.location.href = "/"
        } else {
            setError(true)
        }
    }
    const closeError = () => {
        setError(false)
    }
    return (
        <div className="contenedor-login">
            <h1>Bienvenido(a)</h1>
            <p>Si ya está registrado(a) inicie sesión con su codigo y contraseña suministrada.</p>
            <form id={"form-login"} onSubmit={onSubmitForm}>
                <div className="form-group">
                    <img src={IconCedula} alt="Icono Cedula"/>
                    <input type="text" name="code" id="code" placeholder={"Codigo"} onChange={(e) => {
                        setDatos({...datos, code: e.target.value});
                    }}/>
                </div>
                <div className="form-group">
                    <img src={IconPassword} alt="Icono Correo"/>
                    <input type="password" name="password" id="password" placeholder={"Contraseña"} onChange={(e) => {
                        setDatos({...datos, password: e.target.value});
                    }}/>
                </div>
                <input type="submit" className="input-submit" value={"Iniciar"}/>
            </form>
            <ModalErrorSolicitar show={error}
                                 tituloError={'Usted no esta registrado, comuniquese con el desarrollador.'}
                                 closeError={closeError}/>
        </div>
    );
}

export default AdminLogin;