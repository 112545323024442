import React from 'react';

function NotFound(props) {
    return (
        <div className={"container-notfound"}>
            <h1>404 Not Found</h1>
        </div>
    );
}

export default NotFound;