import Axios from 'axios';
import jwt from 'jsonwebtoken';

const dataToken = jwt.decode(localStorage.getItem('token'));
const sessionServices = {}
sessionServices.login = async (data) => {
    try {
        return await Axios.post('https://visita.kagencia.com/api/doctores/login', data).catch((err) => {
            return err.response
        })
    } catch (e) {
        return e
    }
}
sessionServices.loginAdmin = async (data) => {
    try {
        return await Axios.post('https://visita.kagencia.com/api/admins/login', data).catch((err) => {
            return err.response
        })
    } catch (e) {
        return e
    }
}
sessionServices.isLogged = () => {
    if (localStorage.getItem('token')) {
        return true;
    }
}
sessionServices.dataToken = () => {
    return dataToken;
}
sessionServices.isAdmin = () => {
    // if (dataToken) {
    //     if (dataToken.Role === process.env.REACT_APP_ROLE.toString()){
            return false;
    //     }
    // }
    // return false;
}
export {sessionServices}