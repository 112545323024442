import Axios from "axios";

const token = localStorage.getItem("token");
const preguntasService = {};
preguntasService.getPreguntas = async () => {
  try {
    return await Axios.get(
      "https://visita.kagencia.com/api/preguntas",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  } catch (e) {
    return e;
  }
};
preguntasService.evaluarPreguntas = async (data) => {
  try {
    return await Axios.post(
      "https://visita.kagencia.com/api/preguntas/evaluar",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  } catch (e) {
    return e;
  }
};
preguntasService.postPreguntas = async (data) => {
  try {
    return await Axios.post(
      "https://visita.kagencia.com/api/preguntas",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  } catch (e) {
    return e;
  }
};
export { preguntasService };
