import React, {useState} from 'react';
import './styles.css'
import {capituloService} from "../../Services/capituloServices";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer, toast} from 'react-toastify';

function CrearCapitulo(props) {
    const [datos, setDatos] = useState({
        id: props.id,
        descripcion: ''
    })

    const [porcentaje, setPorcentaje] = useState(0)
    const onSubmitForm = async (e) => {
        e.preventDefault();
        const options = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            onUploadProgress: (progress) => {
                const {loaded, total} = progress
                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    setPorcentaje(percent);
                }
            }
        }
        const formData = new FormData();
        formData.append('id', datos.id);
        formData.append('descripcion', datos.descripcion);
        formData.append('video', document.getElementById('video').files[0]);
        let res;
        if (props.editar) {
            formData.append('idCapitulo', props.datos.idCapitulo)
            res = await capituloService.updateCapitulo(formData, options);
        } else {
            res = await capituloService.addCapituloToVisita(formData, options);
        }
        if (res.status === 200) {
            if (props.editar) {
                props.getData();
            }
            setPorcentaje(100)
            setTimeout(() => {
                setPorcentaje(0)
            }, 1000)
            document.getElementById('form-capitulo').reset();
            toast.success(res.data.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            setDatos({
                ...datos,
                video: '',
                descripcion: ''
            })
        } else {
            toast.error(res.data.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            setPorcentaje(100)
            setTimeout(() => {
                setPorcentaje(0)
            }, 1000)
        }
    }
    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.target.name]: e.target.value
        })
    }
    return (
        <div className={"contenedor-crear-capitulo"}>
            <h2>{props.editar ? "Editar Capitulo" : "Nuevo Capítulo"}</h2>
            <form id={"form-capitulo"} onSubmit={onSubmitForm}>
                {props.editar ? <div className="form-group">
                    <p>ID Capitulo</p>
                    {props.datos.idCapitulo}
                </div> : null}
                <div className="form-group">
                    <p>Descripción del capítulo</p>
                    <textarea name="descripcion" id=" descripcion" onChange={(e) => {
                        handleInputChange(e)
                    }
                    } cols="30" rows="10" required/>
                </div>
                <div className="form-group">
                    <p>Video</p>
                    {datos.video ? <label htmlFor="video">{document.getElementById('video').files[0].name}</label> :
                        <label htmlFor="video">Seleccionar Video</label>}
                    <input type="file" className="input-file" onChange={(e) => {
                        setDatos({...datos, video: e.target.files[0]})
                    }
                    } name="video" id="video"/>
                </div>
                <div className="barra">
                    <div className="barraazul" style={{width: (porcentaje) + '%'}} id={"barraestado"}>
                        <span></span>
                    </div>
                </div>
                <input type="submit" className={"btn-enviar"} value="Agregar Capítulo"/>

            </form>
            <ToastContainer/>
        </div>
    );
}

export default CrearCapitulo;