import React, { useEffect, useState } from "react";
import "./styles.css";
import btnAvanzar from "../../assets/boton_avanzar.svg";
import btnRetroceder from "../../assets/boton_retroceder.svg";
import { visitasServices } from "../../Services/visitasServices";
import { doctorServices } from "../../Services/doctorServices";
import { sessionServices } from "../../Services/sessionServices";
import { toast } from "react-toastify";
import { accionesServices } from "../../Services/accionesServices";
import dayjs from "dayjs";

function Visita(props) {
  const [visita, setVisita] = useState([]);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    getVisita();
    postAccionVisita();
    if (!props.estudio) {
      getProfileDoctor();
    }
    // eslint-disable-next-line
  }, []);
  const getVisita = async () => {
    const res = await visitasServices.getVisita();
    if (res.status === 200) {
      if (res.data.length > 0) {
        if (res.data[0].capitulos) {
          setVisita(res.data[0].capitulos);
          setMaxPage(res.data[0].capitulos.length);
        }
      }
    }
  };
  const getProfileDoctor = async () => {
    const res = await doctorServices.getProfileDoctor(
      sessionServices.dataToken().id
    );
    if (res.status === 200) {
      if (res.data.posicionvisita === "Finalizada") {
        setCurrentPage(maxPage);
      } else {
        setCurrentPage(parseInt(res.data.posicionvisita));
      }
    }
  };
  const aumentarPosicionVisita = async (position) => {
    try {
      await doctorServices.updatePageCurrent({ position });
    } catch (e) {
      toast.error("Lo sentimos, hay un problema con la visita", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };
  const currenCap = (page) => {
    return (
      <div className="container-cap">
        <video
          src={`https://visita.kagencia.com/api/videos/${page.video}`}
          autoPlay={true}
          controls
        />
        <p>{page.descripcion}</p>
      </div>
    );
  };
  const cambiarPosicionVisita = async (n) => {
    if (maxPage > 1) {
      setCurrentPage(currentPage + n);
      await aumentarPosicionVisita(currentPage + n);
    }
  };
  const postAccionVisita = async () => {
    await accionesServices.postAccion({
      accion: "Iniciar_Visita",
      hora: dayjs().format("HH:mm:ss"),
      dia: dayjs().locale("es").format("dddd"),
    });
  };
  return (
    <div className="container-visita">
      {visita.length > 0 ? (
        currenCap(visita[currentPage])
      ) : (
        <h1>Cargando Visita...</h1>
      )}
      {visita.length === 0 ? (
        <h1>Cargando videos...</h1>
      ) : (
        <>
          {currentPage === 0 && maxPage > 1 ? (
            <button
              className={"btn-finalizar"}
              onClick={async () => {
                await cambiarPosicionVisita(1);
              }}
            >
              {props.estudio ? "Iniciar Estudio" : "Iniciar Visita"}
            </button>
          ) : null}
          {currentPage === maxPage - 1 ? (
            <button
              className={"btn-finalizar"}
              onClick={() =>
                props.estudio
                  ? (window.location.href = "/")
                  : (window.location.href = "/preguntas")
              }
            >
              {props.estudio ? "Finalizar Estudio" : "Finalizar Visita"}
            </button>
          ) : null}
          <div className="container-buttons">
            <img
              src={btnRetroceder}
              onClick={async () => {
                if (currentPage === 0) {
                  window.location.href = "/";
                } else {
                  await cambiarPosicionVisita(-1);
                }
              }}
              alt="Retroceder"
            />
            {currentPage !== 0 && currentPage !== maxPage - 1 ? (
              <img
                src={btnAvanzar}
                onClick={async () => {
                  await cambiarPosicionVisita(1);
                }}
                alt="Avanzar"
              />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}

export default Visita;
