import Axios from 'axios';

const token = localStorage.getItem('token');
const visitasServices = {}
visitasServices.postVisita = async (data) => {
    try {
        return await Axios.post('https://visita.kagencia.com/api/visita', data, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).catch((err) => {
            return err.response;
        });
    } catch (e) {
        return e;
    }
}
visitasServices.getVisita = async () => {
    try {
        return await Axios.get('https://visita.kagencia.com/api/visita', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).catch((err) => {
            return err.response
        })
    } catch (e) {
        return e;
    }
}
visitasServices.deleteVisita = async () => {
    try {
        return await Axios.delete('https://visita.kagencia.com/api/visita', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).catch((err) => {
            return err.response
        })
    } catch (e) {
        return e;
    }
}
export {visitasServices}