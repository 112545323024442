import React from "react";
import './App.css';
import Navbar from "./components/Navbar";
import Routes from "./components/Routes";
import {
    BrowserRouter as Router
} from 'react-router-dom'

function App() {
    return (
        <div className="App">
            <div className="container">
                <Router>
                    <Navbar/>
                    <Routes/>
                </Router>
            </div>
        </div>
    );
}

export default App;
