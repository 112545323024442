import React from 'react';
import './styles.css'
import BtnCerrar from '../../assets/cerrar_popup.svg'
function PopUpVideo(props) {
    return (
        <div className={"pop-video"}>
            <img src={BtnCerrar} onClick={()=>props.cerrarPopup()} className={"btn-cerrar"} alt=""/>
            <video src={props.video} autoPlay={true} controls={true}/>
        </div>
    );
}

export default PopUpVideo;