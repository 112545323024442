import React, {useState} from 'react';
import './styles.css';
import {toast, ToastContainer} from "react-toastify";

function AgregarOpcion(props) {
    const [opcion, setOpcion] = useState('');
    const onSubmitForm = (e) => {
        e.preventDefault();
        props.agregarOpcion(opcion)
        toast.success('Se ha agregado la opción', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        document.getElementById('form-opciones').reset();
    }
    return (
        <div className={"contenedor-opcion"}>
            <form onSubmit={onSubmitForm} id={"form-opciones"}>
                <h1>Agregar Opción</h1>
                <p>Texto Opcion</p>
                <input type="text" name="opcion" id="opcion" onChange={(e) => {
                    setOpcion(e.target.value);
                }}/>
                <p>Recuerda agregar la letra que identificara a tu opción</p>
                <button className={"btn-enviar"} type={"submit"}>Agregar</button>
            </form>
            <ToastContainer/>

        </div>
    );
}

export default AgregarOpcion;