import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import IconMaterial from "../../assets/iconomatestudio.svg";
import IconoFlecha from "../../assets/flecha1 (2).png";
import IconoPrescripciones from "../../assets/iconoprescripcion.svg";
import "./styles.css";
import { sessionServices } from "../../Services/sessionServices";
import Modulo from "../ModuloAdmin/Modulo";
import { visitasServices } from "../../Services/visitasServices";

function Home(props) {
  const [visita, setVisita] = useState("");
  useEffect(() => {
    visitasServices.getVisita().then((res) => {
      setVisita(res.data[0].descripcion);
    });
  }, []);
  return (
    <div className="container-home">
      {sessionServices.isLogged() ? (
        <div className="contenedor-logout">
          <button
            className="btn-logout"
            onClick={() => {
              localStorage.removeItem("token");
              if (sessionServices.isAdmin()) {
                window.location.href = "/adminlog";
              } else {
                window.location.href = "/login";
              }
            }}
          >
            Cerrar Sesión <span>X</span>
          </button>
        </div>
      ) : null}
      {sessionServices.isAdmin() ? (
        <Modulo />
      ) : (
        <>
          <h2>
            Seleccione
            <br />
            la Visita Digital Autoguiada:
          </h2>
          {visita ? (
            <NavLink to={"/visita"} className={"link-visita"}>
              Ingrese aquí para realizar su <br />
              <div className="strong">{visita}</div>
            </NavLink>
          ) : (
            <h3>No hay visitas disponibles.</h3>
          )}

          <h2>
            Conozca las opciones adicionales
            <br />
            que Colgate tiene para usted:
          </h2>
          <div
            onClick={() => {
              window.open(
                `https://pres.kagencia.com/login/${localStorage.getItem(
                  "token"
                )}`
              );
            }}
            className="link border-blue"
            target={"__BLANK"}
          >
            <img
              src={IconoFlecha}
              className="flecha-border"
              alt="Flecha prescripciones"
            />
            <img src={IconoPrescripciones} alt="Icono Prescripciones" />
            <p>
              Ingrese aquí para realizar<br></br>
              <strong>Prescripciones</strong>
              <br />
              para sus pacientes
            </p>
          </div>
          <NavLink to={"/estudio"} className={"link"}>
            <img src={IconMaterial} alt="Icono Material de estudios" />
            <p>Material de Estudio</p>
          </NavLink>
        </>
      )}
    </div>
  );
}

export default Home;
