import React, {useState} from 'react';
import './styles.css'
import CrearCapitulo from "../CrearCapitulo/CrearCapitulo";
import EditarCapitulo from "../EditarCapitulo/EditarCapitulo";

function GestionCapitulos(props) {
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showEliminar, setShowEliminar] = useState(false);
    return (
        <div className={"container-gestioncapitulos"}>
            <h1>Gestión de capitulos</h1>
            <div className="wrapper-buttons">
                <button className={"btn-generico"} onClick={() => {
                    setShowAdd(true)
                    setShowEdit(false)
                    setShowEliminar(false)
                }}>Añadir Capitulo
                </button>
                <button className={"btn-generico"} onClick={() => {
                    setShowEdit(true)
                    setShowAdd(false)
                    setShowEliminar(false)
                }}>Editar Capitulo
                </button>
                <button className={"btn-generico"} onClick={() => {
                    setShowAdd(false);
                    setShowEdit(false);
                    setShowEliminar(true);
                }}>Eliminar Capitulo
                </button>
            </div>
            <div className="container-component">
                {showAdd ? <CrearCapitulo/> : null}
                {showEdit ? <EditarCapitulo/> : null}
                {showEliminar ? <EditarCapitulo delete={true}/> : null}
            </div>
        </div>
    );
}

export default GestionCapitulos;