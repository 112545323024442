import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "./Login/Login";
import Home from "./Home/Home.jsx";
import Visita from "./Visita/Visita";
import Questions from "./Questions/Questions";
import Solicitud from "./Solicitud/Solicitud";
import Enviado from "./Enviado/Enviado";
import CrearVisita from "./CrearVisita/CrearVisita";
import Modulo from "./ModuloAdmin/Modulo";
import AdminLogin from "./AdminLogin/AdminLogin";
import { sessionServices } from "../Services/sessionServices";
import NotFound from "./NotFound/NotFound";
import GestionCapitulos from "./GestionCapitulos/GestionCapitulos";
import CrearPreguntas from "./CrearPreguntas/CrearPreguntas";
import RegistrosPendientes from "./RegistrosPendientes/RegistrosPendientes";
import MensajeComplete from "./MensajeComplete/MensajeComplete";
import { doctorServices } from "../Services/doctorServices";

function Routes() {
  const [datosPerfil, setDatosPerfil] = useState({});
  useEffect(() => {
    if (sessionServices.isLogged() && !sessionServices.isAdmin()) {
      doctorServices
        .getProfileDoctor(sessionServices.dataToken().id)
        .then((res) => {
          setDatosPerfil(res.data);
        });
    }
    // eslint-disable-next-line
  }, []);
  const RouteSesions = (props) =>
    sessionServices.isLogged() ? <Redirect to="/" /> : <Route {...props} />;
  const AdminRoute = (props) =>
    sessionServices.isAdmin() ? <Route {...props} /> : <Redirect to={"/"} />;
  const SecureRoute = (props) =>
    sessionServices.isLogged() ? (
      <Route {...props} />
    ) : (
      <Redirect to="/login" />
    );
  const RouteVisita = (props) => {
    if (
      datosPerfil.posicionvisita === "Finalizada" &&
      datosPerfil.respondido &&
      !datosPerfil.solicitado
    ) {
      return <Redirect to={"/solicitar"} />;
    }
    if (
      datosPerfil.posicionvisita === "Finalizada" &&
      datosPerfil.respondido &&
      datosPerfil.solicitado
    ) {
      return <Redirect to="/complete" />;
    }

    return <Route {...props} />;
  };
  const RouteSolicitar = (props) =>
    !datosPerfil.solicitado ? <Route {...props} /> : <Redirect to={"/"} />;
  return (
    <Switch>
      <SecureRoute path="/" exact component={Home} />
      <RouteSesions path="/login" exact component={Login} />
      <RouteVisita path="/visita" exact component={Visita} />
      <SecureRoute path="/preguntas" exact component={Questions} />
      <RouteSolicitar path="/solicitar" exact component={Solicitud} />
      <SecureRoute path="/enviado" exact component={Enviado} />
      <SecureRoute
        path="/estudio"
        exact
        component={() => <Visita estudio={true} />}
      />
      <AdminRoute path="/crearvisita" exact component={CrearVisita} />
      <AdminRoute path="/moduloadmin" exact component={Modulo} />
      <RouteSesions path="/adminlog" exact component={AdminLogin} />
      <AdminRoute path="/gestioncapitulos" exact component={GestionCapitulos} />
      <AdminRoute path="/crearpreguntas" exact component={CrearPreguntas} />
      <AdminRoute
        path="/registrospendientes"
        exact
        component={RegistrosPendientes}
      />
      <SecureRoute path="/complete" exact component={MensajeComplete} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
