import Axios from "axios";
const token = localStorage.getItem("token");
const solicitudService = {};
solicitudService.enviarSolicitud = async (datos) => {
  try {
    return await Axios.post(
      "https://visita.kagencia.com/api/solicitud",
      datos,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  } catch (e) {
    return e;
  }
};
export { solicitudService };
