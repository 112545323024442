import React, { useState } from "react";
import "./styles.css";
import IconCedula from "../../assets/ico_cedula.svg";
import IconCorreo from "../../assets/ico_correo.svg";
import ModalErrorSolicitar from "../ModalForm/ModalErrorSolicitar";
import { sessionServices } from "../../Services/sessionServices";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { accionesServices } from "../../Services/accionesServices";
import dayjs from "dayjs";
import "dayjs/locale/es";

function Login() {
  const [error, setError] = useState(false);
  const [datos, setDatos] = useState({
    cedula: "",
    correo: "",
  });
  const validarForm = () => {
    const form = document.getElementById("form-login");
    if (
      form.elements["cedula"].value === "" ||
      form.elements["correo"].value === ""
    ) {
      setError(true);
    }
  };
  const postAccion = async () => {
    await accionesServices.postAccion({
      accion: "Login",
      hora: dayjs().format("HH:mm:ss "),
      dia: dayjs().locale("es").format("dddd"),
    });
  };
  const onSubmitForm = async (e) => {
    e.preventDefault();
    validarForm();
    const res = await sessionServices.login(datos);
    if (res.status === 200) {
      await postAccion();
      localStorage.setItem("token", res.data.token);
      window.location.href = "/";
    } else {
      setError(true);
      toast.error(res.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };
  const closeError = () => {
    setError(false);
  };
  return (
    <div className="contenedor-login">
      <h1>Bienvenido(a)</h1>
      <p>
        Si ya está registrado(a) inicie la visita digital digitando su número de
        cédula y correo electrónico.
      </p>
      <form id={"form-login"} onSubmit={onSubmitForm}>
        <div className="form-group">
          <img src={IconCedula} alt="Icono Cedula" />
          <input
            type="number"
            min={1}
            pattern="[0-9]*"
            name="cedula"
            id="cedula"
            placeholder={"Cédula"}
            onChange={(e) => {
              setDatos({ ...datos, cedula: e.target.value });
            }}
          />
        </div>
        <div className="form-group">
          <img src={IconCorreo} alt="Icono Correo" />
          <input
            type="email"
            name="correo"
            id="correo"
            placeholder={"Correo Electronico"}
            onChange={(e) => {
              setDatos({ ...datos, correo: e.target.value });
            }}
          />
        </div>
        <input type="submit" className="input-submit" value={"Iniciar"} />
      </form>
      <ModalErrorSolicitar
        show={error}
        tituloError={"Su número de cédula aún no se ha registrado"}
        footer={
          "Para registrarse escriba al correo: colgateprofesional@colgateprofesional.net o llame a la línea gratuita 018000423833"
        }
        closeError={closeError}
      />
      <ToastContainer />
    </div>
  );
}

export default Login;
