import React from 'react';
import IconClose from '../../assets/cerrar_popup.svg';
import IconMano from '../../assets/iconMano.svg';
import './styles.css'

function ModalErrorSolicitar(props) {
    return (
        <div className={props.show ? "modal-form show" : "modal-form"} id={"modal"}>
            <div className="contenedor-modal">
                <div className="contenedor-cerrar">
                    <img src={IconClose} className={"img-cerrar"} onClick={() => {
                        document.getElementById('modal').classList.remove('show')
                        props.closeError();
                    }}  alt="Icono Cerrar"/>
                </div>
                <h1>{props.tituloError}</h1>
                <div className="contenedor-stop">
                    <img src={IconMano} className={"img-stop"} alt="Icono Stop"/>
                </div>
                <div className="footer">
                    <p className="parrafo-modal">{props.footer ? props.footer:''}</p>
                </div>
            </div>

        </div>
    );
}

export default ModalErrorSolicitar;