import Axios from 'axios';

const capituloService = {}
capituloService.addCapituloToVisita = async (datos, options) => {
    try {
        return await Axios.post('https://visita.kagencia.com/api/visita/capitulo', datos, options).catch((err, res) => {
            if (err) {
                return err.response
            }
        });
    } catch (e) {
        return e
    }
}
capituloService.updateCapitulo = async (datos, options) => {
    try {
        return await Axios.put(`https://visita.kagencia.com/api/visita/capitulo`, datos, options).catch((err, res) => {
            if (err) {
                return err.response
            }
        });
    } catch (e) {
        return e;
    }
}
capituloService.deleteCapitulo = async (idCapitulo) => {
    try {
        return await Axios.delete(`https://visita.kagencia.com/api/visita/capitulo/${idCapitulo}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).catch((err) => {
            return err.response;
        })
    } catch (e) {
        return e;
    }
}
export {capituloService}