import React from 'react';
import ColgateLogo from '../assets/Colgate_logo.svg'
import {NavLink} from "react-router-dom";

function Navbar(props) {
    return (
        <>
            <header>
                <div className="logo">
                    <NavLink to={"/"}>
                        <img src={ColgateLogo} alt="Colgate Logo"/>
                    </NavLink>
                </div>
                <div className="Titulo-App">
                    <h2><span style={{color: 'var(--red)'}}>VISITA</span> DIGITAL</h2>
                    <h2>AUTOGUIADA</h2>
                </div>
            </header>

        </>
    );
}

export default Navbar;