import React, {useState} from 'react';
import './styles.css'
import IconEspecialidad from '../../assets/icono_especialidad.svg'
import IconCelular from '../../assets/icono_celular.svg'
import IconDireccion from '../../assets/icono_direccion.svg'
import IconCiudad from '../../assets/icono_ciudad.svg'
import ModalErrorSolicitar from "../ModalForm/ModalErrorSolicitar";
import {solicitudService} from "../../Services/solicitudService";
import {sessionServices} from "../../Services/sessionServices";
import {toast, ToastContainer} from "react-toastify";
import {accionesServices} from "../../Services/accionesServices";
import dayjs from "dayjs";

function Solicitud(props) {
    const [datos, setDatos] = useState({
        idDoctor: sessionServices.dataToken().id,
        codigoColgate: sessionServices.dataToken().codigoColgate,
        nombre: sessionServices.dataToken().nombre,
        cedula: sessionServices.dataToken().cedula,
        email: sessionServices.dataToken().email
    })
    const [error, setError] = useState(false);
    const validarForm = () => {
        const form = document.getElementById('form-solicitud');
        if (form.elements['especialidad'].value === '' || form.elements['celular'].value === '' || form.elements['direccion'].value === '' ||
            form.elements['ciudad'].value === '') {
            setError(true)
        }
    }

    const onSubmitForm = async (e) => {
        validarForm();
        e.preventDefault();
        const res = await solicitudService.enviarSolicitud(datos);
        if (res.status === 200) {
            await postAccionVisita();
            toast.success(res.data.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            return props.history.push({
                pathname: '/enviado',
            });
        } else {
            toast.error(res.data.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        }
    }
    const closeError = () => {
        setError(false)
    }
    const postAccionVisita = async () => {
        await accionesServices.postAccion({
            accion: 'Solicitud_Kit',
            hora: dayjs().format('HH:mm:ss'),
            dia:dayjs().locale('es').format('dddd')
        });
    }
    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.target.name]: e.target.value
        })
    }
    return (
        <div className="container-solicitud">
            <form onSubmit={onSubmitForm} id={"form-solicitud"}>
                <h2>Gracias, usted
                    respondió<br/>acertadamente {props.location.respuestas}/{props.location.preguntas} preguntas</h2>
                <p>Para poderle enviar los productos de Colgate por favor diligencie la siguiente información:</p>
                <div className="form-group">
                    <img src={IconEspecialidad} alt="Icono Especialidad"/>
                    <input type="text" name={"especialidad"} placeholder={"Especialidad"} onChange={(e) => {
                        handleInputChange(e)
                    }}/>
                </div>
                <div className="form-group">
                    <img src={IconCelular} alt="Icono Celular"/>
                    <input type="number" name="celular" pattern="[0-9]*" id="celular" onChange={(e) => {
                        const numbers = /^[0-9]+$/;
                        const celular = document.getElementById('celular');
                        const result = numbers.test(celular.value);
                        if (!result) {
                            celular.value = ''
                        }
                        handleInputChange(e)
                    }} placeholder={"Número de celular"}/>
                </div>
                <div className="form-group">
                    <img src={IconDireccion} alt="Icono Direccion"/>
                    <input type="text" name="direccion" placeholder={"Dirección consultorio"} onChange={(e) => {
                        handleInputChange(e)
                    }}/>
                </div>
                <div className="form-group">
                    <img src={IconCiudad} alt="Icono Ciudad"/>
                    <select name="ciudad" id="ciudad" onChange={(e) => {
                        handleInputChange(e)
                    }}>
                        <option value="">Selecciona una ciudad</option>
                        <option value="BARRANQUILLA">BARRANQUILLA</option>
                        <option value="CARTAGENA">CARTAGENA</option>
                        <option value="MARTA">SANTA MARTA</option>
                        <option value="SOLEDAD">SOLEDAD</option>
                        <option value="TURBACO">TURBACO</option>
                        <option value="NEIVA">NEIVA</option>
                        <option value="IBAGUE">IBAGUE</option>
                        <option value="MONTERIA">MONTERIA</option>
                        <option value="VALLEDUPAR">VALLEDUPAR</option>
                        <option value="MEDELLIN">MEDELLIN</option>
                        <option value="CALI">CALI</option>
                        <option value="MANIZALES">MANIZALES</option>
                        <option value="PEREIRA">PEREIRA</option>
                        <option value="BUCARAMANGA">BUCARAMANGA</option>
                        <option value="FLORIDABLANCA">FLORIDABLANCA</option>
                        <option value="ENVIGADO">ENVIGADO</option>
                        <option value="ITAGUI">ITAGUI</option>
                        <option value="SABANETA">SABANETA</option>
                        <option value="BELLO">BELLO</option>
                        <option value="CUCUTA">CUCUTA</option>
                        <option value="ARMENIA">ARMENIA</option>
                        <option value="BOGOTA">BOGOTA, D.C</option>
                        <option value="DUITAMA">DUITAMA</option>
                        <option value="MARINILLA">MARINILLA</option>
                        <option value="PASTO">PASTO</option>
                        <option value="VILLAVICENCIO">VILLAVICENCIO</option>
                        <option value="CHIA">CHIA</option>
                        <option value="ROLDANILLO">ROLDANILLO</option>
                        <option value="PALMIRA">PALMIRA</option>
                        <option value="QUIMBAYA">QUIMBAYA</option>
                        <option value="SOACHA">SOACHA</option>
                        <option value="PIEDECUESTA">PIEDECUESTA</option>
                        <option value="GRANADA">GRANADA</option>
                        <option value="POPAYAN">POPAYAN</option>
                        <option value="ZIPAQUIRA">ZIPAQUIRA</option>
                        <option value="MOMPOS">MOMPOS</option>
                        <option value="FUNZA">FUNZA</option>
                        <option value="MADRID">MADRID</option>
                        <option value="LA CEJA">LA CEJA</option>
                        <option value="RICAURTE">RICAURTE</option>
                        <option value="JAMUNDI">JAMUNDI</option>
                        <option value="TOCANCIPA">TOCANCIPA</option>
                        <option value="FUSAGASUGA">FUSAGASUGA</option>
                        <option value="SOGAMOSO">SOGAMOSO</option>
                        <option value="SOPO">SOPO</option>
                        <option value="RIOHACHA">RIOHACHA</option>
                        <option value="MONTENEGRO">MONTENEGRO</option>
                        <option value="EL SANTUARIO">EL SANTUARIO</option>
                        <option value="GIRARDOT">GIRARDOT</option>
                        <option value="BARBOSA">BARBOSA</option>
                        <option value="GARAGOA">GARAGOA</option>
                        <option value="BARRANCABERMEJA">BARRANCABERMEJA</option>
                        <option value="COTA">COTA</option>
                        <option value="ALCALA">ALCALA</option>
                        <option value="SAHAGUN">SAHAGUN</option>
                        <option value="CAJICA">CAJICA</option>
                        <option value="SANTO TOMAS">SANTO TOMAS</option>
                        <option value="PUERTO TEJADA">PUERTO TEJADA</option>
                        <option value="SEVILLA">SEVILLA</option>
                        <option value="TULUA">TULUA</option>
                        <option value="CISNEROS">CISNEROS</option>
                        <option value="CALDAS">CALDAS</option>
                        <option value="AJICA">AJICA</option>
                        <option value="YUMBO">YUMBO</option>
                        <option value="IPIALES">IPIALES</option>
                        <option value="GIRON">GIRON</option>
                        <option value="EL CERRITO">EL CERRITO</option>
                        <option value="LA CALERA">LA CALERA</option>
                        <option value="CIRCASIA">CIRCASIA</option>
                        <option value="COROZAL">COROZAL</option>
                        <option value="OCAÑA">OCAÑA</option>
                        <option value="UBATE">UBATE</option>
                        <option value="GUARNE">GUARNE</option>
                        <option value="FACATATIVA">FACATATIVA</option>
                        <option value="TUNJA">TUNJA</option>
                        <option value="CALARCA">CALARCA</option>
                        <option value="RESTREPO">RESTREPO</option>
                        <option value="MOSQUERA">MOSQUERA</option>
                        <option value="GUADALAJARA DE BUGA">GUADALAJARA DE BUGA</option>
                        <option value="BUENAVENTURA">BUENAVENTURA</option>
                        <option value="SAN GI">SAN GIL</option>
                        <option value="PUERTO LOPEZ">PUERTO LOPEZ</option>
                        <option value="RIONEGRO">RIONEGRO</option>
                        <option value="SAN JUAN DEL CESAR">SAN JUAN DEL CESAR</option>
                        <option value="DOSQUEBRADAS">DOSQUEBRADAS</option>
                        <option value="CERETE">CERETE</option>
                        <option value="OIBA">OIBA</option>
                        <option value="VILLA DE LEYVA">VILLA DE LEYVA</option>
                        <option value="HONDA">HONDA</option>
                        <option value="QUIBDO">QUIBDO</option>
                        <option value="LA MESA">LA MESA</option>
                        <option value="VILLA DEL ROSARIO">VILLA DEL ROSARIO</option>
                        <option value="PUERTO COLOMBIA">PUERTO COLOMBIA</option>
                        <option value="GACHANCIPA">GACHANCIPA</option>
                        <option value="95">CARTAGO</option>
                        <option value="EL CARMEN DE VIBORAL">EL CARMEN DE VIBORAL</option>
                        <option value="MAICAO">MAICAO</option>
                        <option value="MALAGA">MALAGA</option>
                        <option value="SANTA ROSA DE CABA">SANTA ROSA DE CABAL</option>
                        <option value="GUATAVITA">GUATAVITA</option>
                        <option value="COPACABANA">COPACABANA</option>
                        <option value="TAME">TAME</option>
                        <option value="NEIRA">NEIRA</option>
                        <option value="RETIRO">RETIRO</option>
                        <option value="CUNDAY">CUNDAY</option>
                        <option value="SINCELEJO">SINCELEJO</option>
                        <option value="AQUITANIA">AQUITANIA</option>
                        <option value="VILLAMARIA">VILLAMARIA</option>
                    </select>
                </div>
                <div className="politica">
                    <p>Política de datos En esta Política, se describe la información que tratamos</p>
                </div>
                <div className="container-radio">
                    <div className="button">
                        <input type="radio" required name="politica" id="politica"/>
                        <label htmlFor="politica">Acepto</label>
                    </div>
                    <a href="https://www.colgatepalmolive.com.co/legal-privacy-policy" target={"__BLANK"}>Leer más</a>
                </div>
                <input type="submit" className="btn-enviar" value="Enviar"/>
            </form>
            <ModalErrorSolicitar show={error}
                                 tituloError={'Debe de completar todos los campos para poder solicitar los productos de colgate'}
                                 closeError={closeError}/>
            <ToastContainer/>
        </div>
    );
}

export default Solicitud;