import React from 'react';
import './styles.css'
import {NavLink} from "react-router-dom";

function Modulo(props) {
    return (
        <div className={"container-modulo-admin"}>
            <h1>Módulo Administrativo</h1>
            <div className="wrapper-modulo">
                <NavLink className="btn-generico" to={"/crearvisita"}>Crear Visita</NavLink>
                <NavLink className="btn-generico" to={"/gestioncapitulos"}>Gestionar Capitulos</NavLink>
                <NavLink className="btn-generico" to={"/crearpreguntas"}>Crear Preguntas</NavLink>
                <NavLink className="btn-generico" to={"/registrospendientes"}>Registros Pendientes</NavLink>
            </div>
        </div>
    );
}

export default Modulo;