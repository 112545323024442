import Axios from "axios";
import { sessionServices } from "./sessionServices";

const doctorServices = {};
const token = localStorage.getItem("token");
doctorServices.getProfileDoctor = async (idUser) => {
  try {
    return await Axios.put(
      `https://visita.kagencia.com/api/doctores/${idUser}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  } catch (e) {
    return e;
  }
};
doctorServices.updatePageCurrent = async (datos) => {
  try {
    return await Axios.put(
      `https://visita.kagencia.com/api/doctores/posicion/${
        sessionServices.dataToken().id
      }`,
      datos,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  } catch (e) {
    return e;
  }
};
doctorServices.finalizarVisitaDoctor = async () => {
  try {
    return await Axios.put(
      `https://visita.kagencia.com/api/doctores/finalizar/${
        sessionServices.dataToken().id
      }`,
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  } catch (e) {
    return e;
  }
};
export { doctorServices };
