import React, {useState} from 'react';
import './styles.css'
import PopUpGenerico from "../PopUpGenerico/PopUpGenerico";
import AgregarOpcion from "../AgregarOpcion/AgregarOpcion";
import uniqid from 'uniqid';
import {toast, ToastContainer} from "react-toastify";
import {preguntasService} from "../../Services/preguntasServices";

function CrearPreguntas(props) {
    const [showPopUpOpcion, setShowPopUpOpcion] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const [pregunta, setPregunta] = useState('');
    const [opcionCorrecta, setOpcionCorrecta] = useState('');
    const [opcionesPregunta, setOpcionesPregunta] = useState([]);
    const cerrarPopUp = () => {
        setShowPopUpOpcion(false)
    }
    const agregarOpcion = (datos) => {
        setOpcionesPregunta([...opcionesPregunta, datos]);
    }
    const comprobarPregunta = () => {
        if (!pregunta || !opcionesPregunta || !opcionCorrecta) {
            toast.error('Te hacen falta llenar campos.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            return false
        }
        return true;
    }
    const agregarPregunta = () => {
        if (comprobarPregunta()) {
            const preguntaToArray = {
                "pregunta": pregunta,
                idPregunta: uniqid(),
                opciones: opcionesPregunta,
                correcta: opcionCorrecta
            }
            setPreguntas([...preguntas, preguntaToArray])
            document.getElementById('form-crear-preguntas').reset();
            toast.success('Se ha agregado la pregunta', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            setOpcionesPregunta([]);
        }
    }
    const onSubmitForm = async (e) => {
        e.preventDefault();
        const data = {
            "preguntas": preguntas
        }
        if (data.preguntas) {
            const res = await preguntasService.postPreguntas(data)
            if (res.status === 200) {
                toast.success(res.data.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
        } else {
            toast.error('Por favor, agrega las preguntas', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        }
    }
    return (
        <div className="contenedor-crear-preguntas">
            <h1>Crear preguntas para la visita</h1>
            <div className="contenedor-form-preguntas">
                <form onSubmit={onSubmitForm} id={"form-crear-preguntas"}>
                    <p>Enunciado de la pregunta:</p>
                    <input type="text" name="enunciado" id="enunciado" onChange={(e) => {
                        setPregunta(e.target.value)
                    }}/>
                    <p>Opciones</p>
                    <div className="btn-generico showpopup" onClick={() => {
                        setShowPopUpOpcion(true)
                    }}>Agregar opción
                    </div>
                    <p>Selecciona la opcion correcta</p>
                    <div className={"contenedor-radios"}>
                        {opcionesPregunta ? opcionesPregunta.map((opcion, index) => (
                            <div key={index} className={"form-group"}>
                                <input type="radio" name="correcta" id={opcion} onChange={(e) => {
                                    setOpcionCorrecta(opcionesPregunta[index])
                                }}/>
                                <label htmlFor={opcion}>{opcion}</label>
                            </div>
                        )) : null}
                    </div>
                    <hr/>
                    <div className="btn-generico" onClick={agregarPregunta}>Agregar Pregunta</div>
                    {preguntas.length > 0 ? <input type="submit" className={"btn-generico enviar"} value="Enviar Preguntas"/>
                        : null}
                </form>
            </div>
            {showPopUpOpcion ?
                <PopUpGenerico componente={<AgregarOpcion agregarOpcion={agregarOpcion}  />} cerrarPopup={cerrarPopUp}/>
                : null}
            <ToastContainer/>
        </div>
    );
}

export default CrearPreguntas;