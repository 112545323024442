import React, {useEffect, useState} from 'react';
import './styles.css'
import BtnAtras from '../../assets/boton_retroceder.svg'
import {preguntasService} from "../../Services/preguntasServices";
import {sessionServices} from "../../Services/sessionServices";
import {doctorServices} from "../../Services/doctorServices";
import {toast} from "react-toastify";
import {accionesServices} from "../../Services/accionesServices";
import dayjs from "dayjs";

function Questions(props) {
    const [loading, setLoading] = useState(true)
    const [datos, setDatos] = useState([]);
    const [questions, setQuestions] = useState([])
    useEffect(() => {
        getPreguntas()
        finalizarVisita();
        // eslint-disable-next-line
    }, [])
    const getPreguntas = async () => {
        const res = await preguntasService.getPreguntas();
        if (res.status === 200) {
            setQuestions(res.data[0].preguntas);
            setLoading(false)
        }
    }
    const finalizarVisita = async () => {
        try {
            await doctorServices.finalizarVisitaDoctor()
        } catch (e) {
            toast.error('Lo sentimos, hubo un problema al tratar de finalizar la visita',
                {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        }
    }
    const handleInputChange = (e, idPregunta, index) => {
        if (!datos[index]) {
            setDatos(prevState => [...prevState, {idPregunta: idPregunta, respuesta: e.target.value}]);
        } else {
            let newArray = [...datos]
            newArray[index] = {idPregunta: idPregunta, respuesta: e.target.value};
            setDatos(newArray);
        }
    }
    const onSubmitForm = async (e) => {
        e.preventDefault();
        try {
            const res = await preguntasService.evaluarPreguntas({datos, idDoctor: sessionServices.dataToken().id});
            if (res.status === 200) {
                await postAccionVisita();
                redirect(res);
            }
        }catch (e) {
            console.log(e)
        }
    }
    const postAccionVisita = async () => {
        await accionesServices.postAccion({
            accion: 'Preguntas',
            hora: dayjs().format('HH:mm:ss'),
            dia:dayjs().locale('es').format('dddd')
        });
    }
    const redirect = (res) => {
        return props.history.push({
            pathname: '/solicitar',
            respuestas: res.data.resultado,
            preguntas: questions.length,
        });
    }

    return (
        <div className="container-questions">
            <h1>Para recibir los productos de Colgate responda las siguientes preguntas.</h1>
            <form className="form-questions" onSubmit={onSubmitForm}>
                {loading ? <h1>Cargando...</h1> : questions.map((pregunta, index_pregunta) => (
                    <div key={index_pregunta} style={{width: "100%"}}>
                        <p>{pregunta.pregunta}</p>
                        {pregunta.opciones.map((opcion, index_respuestas) => (
                            <div className="form-group" key={index_respuestas}>
                                <input type="radio" name={pregunta.idPregunta} className="radio" required
                                       id={`p${index_pregunta}-${index_respuestas}`}
                                       onChange={(e) => handleInputChange(e, pregunta.idPregunta, index_pregunta)}
                                       value={opcion}/>
                                <label htmlFor={`p${index_pregunta}-${index_respuestas}`}>{opcion}</label>
                            </div>
                        ))}
                    </div>
                ))}

                <input type="submit" className="btn-finalizar" value={"Finalizar Preguntas"}/>
            </form>
            <div className="container-btn">
                <div onClick={() => {
                    window.location.href = "/visita"
                }}>
                    <img src={BtnAtras} className={"btn-atras"} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default Questions;